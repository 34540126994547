export const icAccountClientData = require("images/ic_account_client_data.svg");
export const icAccountDiscounts = require("images/ic_account_discounts.svg");
export const icAccountPlanTactico = require("images/ic_account_plan_tactico.svg");
export const icAprobMan = require("images/ic_aprob_man.svg");
export const icAprobWoman = require("images/ic_aprob_woman.svg");
export const icAttachDoc = require("images/ic_attach_doc.svg");
export const icBack = require("images/ic_back.svg");
export const icBattery = require("images/ic_battery.svg");
export const icBell = require("images/ic_bell.png");
export const icBtu = require("images/ic_btu.svg");
export const icCar = require("images/ic_car.svg");
export const icChevronDownFilled = require("images/ic_chevron_down_filled.svg");
export const icClose = require("images/ic_close.svg");
export const icMenu = require("images/ic_menu.svg");
export const icMore = require("images/ic_more.svg");
export const icMoto = require("images/ic_moto.svg");
export const icProducts = require("images/ic_products.svg");
export const icQuote = require("images/ic_quote.svg");
export const icOrders = require("images/ic_orders.svg");
export const icAccountStates = require("images/ic_account_states.svg");
export const icInvoice = require("images/ic_invoice.svg");
export const icSearch = require("images/ic_search.svg");
export const icSearch2 = require("images/ic_search2.svg");
export const icSupport = require("images/ic_support.svg");
export const icSignOff = require("images/ic_sign_off.svg");
export const icPlus = require("images/ic_plus.svg");
export const icMinus = require("images/ic_minus.svg");
export const icTrash = require("images/ic_trash.svg");
export const icTrash2 = require("images/ic_trash2.svg");
export const icMarker = require("images/ic_marker.svg");
export const icTruck = require("images/ic_truck.svg");
export const icNext = require("images/ic_next.svg");
export const icInvoiceDownload = require("images/ic_invoice_download.svg");
export const icGuideDownload = require("images/ic_guide_download.svg");
export const icUserWithBack = require("images/ic_user_with_back.svg");
export const icSend = require("images/ic_send.svg");
export const icDownload = require("images/ic_download.svg");
export const icDownload2 = require("images/ic_download2.svg");
export const icEye = require("images/ic_eye.svg");
export const icEdit = require("images/ic_edit.svg");
export const icPhone = require("images/ic_phone.svg");
export const icShoppingCartClear = require("images/ic_shopping_cart_clear.svg");
export const icUserHeadPhones = require("images/ic_user_headphones.png");
export const icHome = require("images/ic_home.png");
export const icCheck = require("images/ic_check.svg");
export const imgBBVA = require("images/img_bbva.png");
export const imgBCP = require("images/img_bcp.png");
export const imgBN = require("images/img_bn.png");
export const imgCaja = require("images/img_caja.png");
export const imgIbk = require("images/img_ibk.png");
export const icRequirement = require("images/ic_requirement.png");
export const icCalendar = require("images/ic_calendar.svg");
export const icCalendar2 = require("images/ic_calendar2.svg");
export const imgCalendar = require("images/img_calendar.png");
export const icClock = require("images/ic_clock.svg");
export const icPhoneOutline = require("images/ic_phone_outline.svg");
export const imgBTUSaleSuccess = require("images/img_btu_sale_success.png");
export const imgClock = require("images/img_clock.png");
export const imgHomeOutline = require("images/img_home_outline.png");

export default {
	icAccountClientData,
	icAccountDiscounts,
	icAccountPlanTactico,
	icAprobMan,
	icAprobWoman,
	icAttachDoc,
	icBack,
	icBattery,
	icBell,
	icBtu,
	icCar,
	icChevronDownFilled,
	icClose,
	icMenu,
	icMore,
	icMoto,
	icProducts,
	icQuote,
	icOrders,
	icAccountStates,
	icInvoice,
	icSearch,
	icSearch2,
	icSupport,
	icSignOff,
	icPlus,
	icMinus,
	icTrash,
	icTrash2,
	icMarker,
	icTruck,
	icNext,
	icInvoiceDownload,
	icGuideDownload,
	icUserWithBack,
	icSend,
	icDownload,
	icDownload2,
	icEye,
	icEdit,
	icPhone,
	icShoppingCartClear,
	icUserHeadPhones,
	icHome,
	icCheck,
	imgBBVA,
	imgBCP,
	imgBN,
	imgCaja,
	imgIbk,
	icRequirement,
	icCalendar,
	icCalendar2,
	icClock,
	imgCalendar,
	imgClock,
	imgHomeOutline,
	icPhoneOutline,
	imgBTUSaleSuccess,
};
