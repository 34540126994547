"use strict";
//list
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERoadmapGetResponseObjetivoContactoSubChecklistOpciones = exports.RoadmapObjectiveContact = void 0;
var RoadmapObjectiveContact;
(function (RoadmapObjectiveContact) {
    RoadmapObjectiveContact["Prospecting"] = "1";
    RoadmapObjectiveContact["Planning"] = "1";
})(RoadmapObjectiveContact = exports.RoadmapObjectiveContact || (exports.RoadmapObjectiveContact = {}));
var ERoadmapGetResponseObjetivoContactoSubChecklistOpciones;
(function (ERoadmapGetResponseObjetivoContactoSubChecklistOpciones) {
    ERoadmapGetResponseObjetivoContactoSubChecklistOpciones["code_yes_not_itse"] = "10108";
    ERoadmapGetResponseObjetivoContactoSubChecklistOpciones["code_yes_not_license_func"] = "10112";
})(ERoadmapGetResponseObjetivoContactoSubChecklistOpciones = exports.ERoadmapGetResponseObjetivoContactoSubChecklistOpciones || (exports.ERoadmapGetResponseObjetivoContactoSubChecklistOpciones = {}));
